<template>
  <div>
    <!-- 详情页面规则公用组件 -->

    <div class="regul">
      <div style="margin-top: 129px">
        <img src="../assets/images/jingpai.png" alt="" />
      </div>
      <div style="margin-left: 40px; width: 100%">
        <div class="title">
          <div>竞价规则</div>
          <div @click="centerDialog" style="cursor:pointer">查看竞价须知</div>
        </div>
        <div class="item_images">
          <div class="image_on"></div>
          <div>
            本次竞价时间：{{ detail.startTime }}到{{ detail.endTime }}持续
            {{ detail.countDown }}
          </div>
        </div>
        <div class="item_images">
          <div class="images_t"></div>
          <div>本次竞价缴纳保证金方可参与</div>
        </div>
        <div class="item_images">
          <div class="images_s"></div>
          <div>最低{{selectOrderType==2?'降价':'加价'}}幅度{{ detail.lowestDiffPrice }}元</div>
        </div>
        <div class="item_images">
          <div class="images_n"></div>
          <div>
            {{selectOrderType==2? (sets.s1==0?'本次竞价报价必须小于底价':"本次竞价报价可以等于底价"):(sets.s1 == 0?'本次竞价报价必须大于底价': "本次竞价报价可以等于底价")
            }}
          </div>
        </div>
        <div class="item_images">
          <div class="images_o"></div>
          <div>本次竞价的标底价不可拆分，参与竞价的数量必须为全部数量</div>
        </div>
        <div class="item_images">
          <div class="images_p"></div>
          <div>
            {{
              sets.s2 == 1
                ? "本次竞价结束前不可以查看其他客户报价"
                : "本次竞价结束前可查看其他客户报价"
            }}
          </div>
        </div>
        <div class="item_images">
          <div class="images_m"></div>
          <div>
            若最终报价同为{{
              $route.query.selectOrderType == 2 ? "最低" : "最高"
            }}则最先报价者中标
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="竞价须知"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span>{{ tradeBidding.biddingNotice }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { tradeDtetails } from "@/api/public.js";
export default {
  data() {
    return {
      centerDialogVisible: false,
      tradeBidding: {},
      detail: {},
      sets: {},
      selectOrderType:0,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {
    data(val) {
      console.log(val)
      this.bindData(val);
    },
  },
  mounted() {
    this.selectOrderType=this.$route.query.selectOrderType
    this.getlisst();
  },
  methods: {
    bindData(item) {
		// debugger
      let countDown = this.countDownFn(
        item.tradeBidding.startTime,
        item.tradeBidding.endTime
      );
      this.sets = {};
      let obj = {
        接收底价报价: "s1",
        报价隐藏: "s2",
        公司名称隐藏: "s3",
      };
      item.sets.forEach((item) => {
        this.sets[obj[item.name]] = item.value;
      });
      console.log(this.sets,'sss');
      this.detail = item.tradeBidding;
      this.$set(this.detail, "countDown", countDown);
    },
    countDownFn(st, et) {
	  //ie只支持"2019/04/26 12:23:22"格式字符串转时间不支持"2022-08-26 00:00:00"
      let startTime = new Date(Date.parse(st.replace(/-/g,"/"))).getTime();
      let endTime = new Date(Date.parse(et.replace(/-/g,"/"))).getTime();
      let endDate = endTime - startTime;
      let d = Math.floor(endDate / 1000 / 60 / 60 / 24);
      let h = Math.floor((endDate / 1000 / 60 / 60) % 24);
      let m = Math.floor((endDate / 1000 / 60) % 60);
      let s = Math.floor((endDate / 1000) % 60);
      let time =
        "" +
        d +
        "天" +
        (h < 10 ? "0" + h : h) +
        "小时" +
        (m < 10 ? "0" + m : m) +
        "分" +
        (s < 10 ? "0" + s : s) +
        "秒";
      return time;
    },
    getlisst() {
      let goodsId = this.$route.query.goodsId;
      tradeDtetails(goodsId).then((res) => {
        if (res.code == 0) {
          this.tradeBidding = res.data.tradeBidding;
        }
      });
    },
    centerDialog() {
      console.log(this.tradeBidding.biddingNotice);
      if (this.tradeBidding.biddingNotice != "") {
        this.centerDialogVisible = true;
      }else{
        this.$message('暂无竞价须知');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item_images {
  letter-spacing: 2px;
  display: flex;
  line-height: 40px;
  .image_on {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 7px;
  }
  .images_t {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -30px;
  }
  .images_s {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -64px;
  }
  .images_n {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -100px;
  }
  .images_o {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -136px;
  }
  .images_p {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -172px;
  }
  .images_m {
    background: url("../assets/images/num.png") no-repeat;
    width: 60px;
    height: 40px;
    background-position: 0 -205px;
  }
}
.title {
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
  :nth-child(1) {
    font-size: 20px;
  }
  :nth-child(2) {
    font-size: 14px;
    color: blue;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.regul {
  width: 100%;
  display: flex;
  // margin-top: 20px;
  border: 1px solid #fad8c3;
  // padding: 10px;
  font-size: 16px;
  line-height: 30px;
  padding-left: 50px;
  padding-top: 37px;
  padding-bottom: 30px;
}
</style>